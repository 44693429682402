import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import "./ticketPreview.css"
import { useHistory } from 'react-router';
import { faBars, faCheckCircle, faFilePdf, faFolder, faMoneyBill, faPuzzlePiece, faTruck } from '@fortawesome/free-solid-svg-icons';
import IconButtonCustom from '../shared/IconButtonCustom';
import NotasModal from './NotasModal';
import Requester from '../shared/Requester';
import Swal from "sweetalert2";
import AuthenticatedInput from "../shared/AuthenticatedInput";

const getCitaCJA = proceso => {
    const actosPostFirma = proceso.actosPostFirma;
        if (actosPostFirma && (!actosPostFirma.numeroReistro || actosPostFirma.numeroReistro === "") && actosPostFirma.ingresoFecha){
                if(!actosPostFirma.ingresoFecha.fecha)
                    return <><Row>Cita Cja: {moment(actosPostFirma.citaCJAFecha.fecha).format("DD/MM/YYYY")}</Row></>
        }
    return null;
}

const TiketPreview = ({ ticket, onUpdate }) => {
    const stGeneral = [
        "EN ELABORACION",
        "PROYECTO ENVIADO",
        "INTEGRACION PARA CJA",
        "EN CJA",
        "INCIDENCIA CJA",
        "PARA FIRMA",
        "PARA ENTREGA",
        "PARA ENTREGA/ EN RPP",
        "PARA ENTREGA/ INTEGRACION RPP",
        "PARA ENTREGA/ SALIDA S/ RPP",
        "PARA ENTREGA/ INCIDENCIA RPP",
        "PARA ENTREGA/ CONST. RPP SIN ENTREGAR",
        "ENTREGADO",
        "ENTREGADO/ EN RPP",
        "ENTREGADO/ INCIDENCIA RPP",
        "ENTREGADO/ INTEGRACION RPP",
        "ENTREGADO/ SALIDA S/ RPP",
        "ENTREGADO/ CONST. RPP SIN ENTREGAR",
        //TODO: Algunos estatus ya no se van a utilizar, ver con carlos cuales serían.
        "SALIDA S/RPP",
        "INCIDENCIA RPP",
        "EN RPP",
        "PROCESO ENTREGA",
        "PROCESO ENTREGA/ INTEGRACION RPP",
        "CONCLUIDO/ EN RPP",
        "CONCLUIDO",
    ]

    function allowWrite(permisos) {
        return permisos.admin
    }

    const AuthInput = AuthenticatedInput(allowWrite)

    const history = useHistory();
    const [showNotas, setShowNotas] = useState(false)
    const [ticketSelected, setTicketSelected] = useState(ticket)
    const [estatus, setEstatus] = useState();
    const [mesa, setMesa] = useState("");

    function conNotas() {
        let notas = 0;
        Object.keys(ticket).forEach(modulo => {
            if (ticket[modulo]?.notas && ticket[modulo].notas.length > 0)
                notas += ticket[modulo].notas.length
        })
        return notas
    }

    function getArmadoShowStatus(armado) {
        let status = "No enviado";
        if (armado && armado.registros) {
            const procesoArmado = armado.registros.find(r => r.proceso === ticketSelected._id)
            if (procesoArmado) {
                if (procesoArmado.estatus !== "Firmado mediador") {
                    status = "En proceso";
                } else {
                    status = "Terminado";
                }
            }
        }
        return status;
    }

    function getSeguimientoShowStatus() {
        let status = "En trámite";
        if(!ticketSelected.seguimiento) {
            status = "Sin seguimiento";
        } else {
            const procesoSeguimiento = ticketSelected.seguimiento.folios.find(f => f.ticket == ticketSelected.ticket);
            if(procesoSeguimiento) {
                if(procesoSeguimiento.inscripcionCJA && procesoSeguimiento.numeroRegistro) {
                    status = "Terminado"
                } else {
                    if(ticketSelected.seguimiento.estatus !== "En trámite") {
                        status = "En proceso"
                    }
                }
            }
        }
        return status;
    }

    function getColorSeguimiento(status) {
        switch (status) {
            case "En proceso":
                return "#d9d200";
            case "En trámite":
                return "#ff5200";
            case "Terminado":
                return "#86df8d";
            default:
                return "#e41b23";
        }
    }

    function getColor(status) {
        switch (status) {
            case "CONCLUIDO":
                return "#a109a7";
            case "CON FACTURA":
                return "#86df8d";
            case "SIN FACTURA":
                return "#e41b23";
            case "CON PAGO":
                return "#86df8d";
            case "SIN PAGO":
                return "#e41b23";
            case "TICKET CANCELADO":
                return "#e41b23";
            // case para tramitación
            case "No enviado":
                return "#e41b23";
            case "En proceso":
                return "#fbbc04";
            case "Terminado":
                return "#86df8d";
            default:
                return "#86df8d";
        }
    }

    useEffect(() => {
        setTicketSelected(ticket);
    }, [])

    useEffect(() => {
        async function postStatus() {
            await Requester.post(`proceso/${ticket.unidad._id}/${ticket._id}/estatus`, { estatus });
            await onUpdate();
            Swal.fire({
                icon: "success",
                title: "Estatus Guardado"
            })
        }
        if (estatus) {
            postStatus();
            setTicketSelected({ ...ticketSelected, estatus });
        }

    }, [estatus])

    useEffect(() => {
        async function postMesa() {
            await Requester.post(`proceso/${ticket.unidad._id}/${ticket._id}/mesa`, { mesa });
            await onUpdate();
            Swal.fire({
                icon: "success",
                title: "Mesa Guardada"
            })
        }
        if (mesa) {
            postMesa();
            setTicketSelected({ ...ticketSelected, mesa });
        }
    }, [mesa])

    const getFirmasCompletadas = ticket => {
        let firmados = 0;
        if (ticket && ticket.firmantes) {
            ticket.firmantes.map(f => {
                if (f.firmado && f.firmado === true) {
                    firmados++
                }
                return
            })
            return firmados
        }
        return 0
    }

    function getEntregadoStatus(ticket) {
        let acuses = 0 
        let firmantes = 0

        if (ticket && ticket.firmantes && ticket.entrega && ticket.entrega.acuses) {
            //Entregado
            acuses = ticket.entrega.acuses.length
            firmantes = ticket.firmantes.length
            return `${acuses}/${firmantes}`
        }
        else {
            return 'No entregado'
        }
    }

    function getEntregadoColor(ticket) {
        const res = getEntregadoStatus(ticket)
        const splited = res.split('/')
        if (splited[0] === splited[1]) {
            return true
        }
        return false
    }

    const getFirmas = ticket => {
        if (ticket.firmantes) {
            return `${getFirmasCompletadas(ticket)}/${ticket.firmantes.length}`
        }
        return `N/A`
    }

    return (
        <div>
            <Container className="ticket wrapper pt-3 pb-3 mt-1 mb-1">
                <Row>
                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faFolder}
                                tooltip={ticket.estatus ? ticket.estatus : "EN ELABORACION"}
                                color={getColor(ticket.estatus ? ticket.estatus : "EN ELABORACION")}
                            />
                        }
                    </Col>
                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faFilePdf}
                                tooltip={ticket?.pagoFacturacion?.numeroFactura &&
                                    ticket?.pagoFacturacion?.numeroFactura !== "" ? "CON FACTURA" : "SIN FACTURA"}
                                color={getColor(ticket?.pagoFacturacion?.numeroFactura &&
                                    ticket?.pagoFacturacion?.numeroFactura !== "" ? "CON FACTURA" : "SIN FACTURA")}
                            />
                        }
                    </Col>
                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faMoneyBill}
                                tooltip={ticket?.pagoFacturacion?.pagadoFecha &&
                                    ticket?.pagoFacturacion?.pagadoFecha?.fecha ? "CON PAGO" : "SIN PAGO"}
                                color={getColor(ticket?.pagoFacturacion?.pagadoFecha &&
                                    ticket?.pagoFacturacion?.pagadoFecha?.fecha ? "CON PAGO" : "SIN PAGO")}
                            />
                        }
                    </Col>
                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faPuzzlePiece}
                                tooltip={`Armado: ${ticket.tramitacion?.armado?.numero ? ticket.tramitacion?.armado?.numero : 'N/A'} - ${getArmadoShowStatus(ticket.tramitacion?.armado)}`}
                                color={getColor(getArmadoShowStatus(ticket.tramitacion?.armado))}
                            />
                        }
                    </Col>
                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faTruck}
                                tooltip={`Seguimiento: ${ticket.seguimiento?.numero ? ticket.seguimiento?.numero : 'N/A'} - ${getSeguimientoShowStatus()}`}
                                color={getColorSeguimiento(getSeguimientoShowStatus())}
                            />
                        }
                    </Col>

                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginRight: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faCheckCircle}
                                tooltip={`Entregado ${getEntregadoStatus(ticket)}`}
                                color={getEntregadoColor(ticket) ? '#86df8d' : '#e41b23'}
                            />
                        }
                    </Col>

                    <Col className="col align-self-end ml-0 mr-0" xs={1} style={{ marginLeft: "auto" }}>
                        {conNotas() > 0 ?
                            <IconButtonCustom
                                faIcon={faBars}
                                tooltip="Notas"
                                color="#e41b23"
                                onClick={() => setShowNotas(!showNotas)} />
                            : <br />}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Row>Ticket: {ticket && ticket.ticket}</Row>
                        {ticket.actosPostFirma.citaCJAFecha && ticket.actosPostFirma.citaCJAFecha.fecha && 
                            getCitaCJA(ticket)
                        }
                    </Col>
                    <Col xs={6}>
                        <Row>Revisado: {moment(ticket.updatedAt).format("DD/MM/YYYY")}</Row>
                        <Row>Creado: {moment(ticket.createdAt).format("DD/MM/YYYY")}</Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AuthInput type="select"
                            value={ticket.estatus}
                            onChange={e => setEstatus(e.target.value)}>
                            {
                                stGeneral ? stGeneral.map(u => (
                                    <option key={stGeneral.indexOf(u)} value={u}>{u}</option>
                                )) : null
                            }
                        </AuthInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Input type="select"
                            value={ticket.mesa}
                            onChange={e => setMesa(e.target.value)}>
                            <option key='0' value="">Seleccionar mesa ...</option>
                            {
                                ticket.infoPreliminar?.agentes ? ticket.infoPreliminar.agentes.map(a => (
                                    <option key={Math.random().toString()} value={a._id}>{a.correo}</option>
                                )) : null
                            }
                        </Input>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12} >
                        <Row><b>Folio: {ticket.solicitudFolio && ticket.solicitudFolio.numeroFolio ? `${ticket.unidad.prefijo}-${ticket.solicitudFolio.numeroFolio} - [${moment(ticket.solicitudFolio.fechaFolio).format("DD/MM/YY")}]` : "N/A"}</b></Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row className="ticket bold" >{ticket.clienteCorporativo}</Row>
                        <Row><p>Asunto: <b>{ticket.infoPreliminar.asunto}</b></p></Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row>Firmas: {getFirmas(ticket)}</Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ marginLeft: "6px" }}>
                        {ticket.firmantes && ticket.firmantes.map(f => {
                            return (
                                <Row className={f.firmado ? "" : "ticket red"}>{f.nombre}</Row>
                            )
                        })}
                    </Col>
                </Row>
                <Row className="ticket buttonWrapper">
                    <Col xs={6}>
                        <Button color="danger" type="button"
                            onClick={() => { history.push(`proceso/Firma/${ticket._id}`) }}
                            disabled={!ticket.solicitudFolio || !ticket.solicitudFolio.folio}
                        >Firmar</Button>
                    </Col>
                    <Col xs={6}>
                        <Button color="secondary" type="button" onClick={() => { history.push(`proceso/infoPreliminar/${ticket._id}`) }}>Detalles</Button>
                    </Col>
                </Row>
            </Container>
            <NotasModal isOpen={showNotas} setIsOpen={setShowNotas} ticket={ticket} />
        </div>
    );
};

export default TiketPreview;